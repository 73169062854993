//
// Check/radio
//

.form-check {
  display: block;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-left;
  margin-bottom: $form-check-margin-bottom;

  .form-check-input {
    float: left;
    margin-left: $form-check-padding-left * -1;
  }
}

.form-check-input {
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: ($line-height-base - $form-check-input-width) / 2; // line-height minus check height
  vertical-align: top;
  background-color: $form-check-input-bg;
  border: $form-check-input-border;
  appearance: none;
  color-adjust: exact; // Keep themed appearance for print

  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
  }

  &[type="radio"] {
    @include border-radius($form-check-radio-border-radius);
  }

  &:active {
    filter: $form-check-input-active-filter;
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 0;
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &:checked {
    background-color: $form-check-input-checked-bg-color;
    background-repeat: $form-check-input-checked-bg-repeat;
    background-position: $form-check-input-checked-bg-position;
    background-size: $form-check-input-checked-bg-size;
    border-color: $form-check-input-checked-border-color;

    &[type="checkbox"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image), linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
        background-size: $form-check-input-checked-bg-size, auto;
      } @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }

    &[type="radio"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-radio-checked-bg-image), linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
        background-size: $form-check-input-checked-bg-size, auto;
      } @else {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    background-repeat: $form-check-input-indeterminate-bg-repeat;
    background-position: $form-check-input-indeterminate-bg-position;
    @if $enable-gradients {
      background-image: escape-svg($form-check-input-indeterminate-bg-image), linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
      background-size: $form-check-input-checked-bg-size, auto;
    } @else {
      background-image: escape-svg($form-check-input-indeterminate-bg-image);
      background-size: $form-check-input-indeterminate-bg-size;
    }
    border-color: $form-check-input-indeterminate-border-color;
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity: .5;
    }
  }
}

.form-check-label {
  margin-bottom: 0;
  cursor: $form-check-label-cursor;
}

//
// Switch
//

.form-switch {
  padding-left: $form-switch-padding-left;

  .form-check-input {
    width: $form-switch-width;
    margin-left: $form-switch-padding-left * -1;
    background-image: escape-svg($form-switch-bg-image);
    background-repeat: no-repeat;
    background-position: left center;
    background-size: $form-switch-bg-size; // Get a 1px separation
    @include border-radius($form-switch-border-radius);
    color-adjust: exact; // Keep themed appearance for print
    // Todo: Figure out how to tackle these, with or without mixin?
    // transition: $form-switch-transition;
    // transition-property: $form-switch-transition-property;

    &:focus {
      background-image: escape-svg($form-switch-focus-bg-image);
    }

    &:checked {
      background-position: $form-switch-checked-bg-position;

      @if $enable-gradients {
        background-image: escape-svg($form-switch-checked-bg-image), linear-gradient(180deg, $form-check-input-checked-bg-color, lighten($form-check-input-checked-bg-color, 10%));
        background-size: $form-switch-bg-size, auto;
      } @else {
        background-image: escape-svg($form-switch-checked-bg-image);
      }
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: $form-check-inline-margin-right;
}
